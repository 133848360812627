<template>
  <div>
    <div class="container" >
     
      <div class="overline text-center">{{ address ? "Your Account" : "Sign in" }}</div>
      <div :class="!address && 'card'">
      <div v-if="!address" >
        <form>
        <!--- <input 
          type="text"
          v-model="password"
          class="password"
          placeholder="Password (privkey)"
        />--->
       <v-text-field v-if="!privkey" type="password" class="password" 
          v-model="password"
          
          placeholder="Password (privkey)"> </v-text-field>
          </form>
      </div>
   
  <div v-if="!address ">
      <div  v-if="!privkey"  >
        <v-btn block
          small
          
          @click="signIn"
        >
          Sign in
        </v-btn>
        
      </div>
      <div  v-else  >
        <v-btn block
          small
          
          @click="trySignIn"
        >
          Sign in
        </v-btn>
        
      </div>
  </div>
      <div v-else class="account">
        <div class="card">
           <v-row class="justify-center pa-4">
          <v-btn plain icon to="/account" >
       <v-icon large> mdi-account
      </v-icon></v-btn></v-row>
          <div class="card__row">
            
            
            <div class="card__desc font-weight-medium caption">
              {{ address }}
            </div>
          </div>
          <div class="card__row caption font-weight-light justify-center">
            <span>
              You have 
              <span
                class="coin__amount"
                v-for="b in balances" :key="b.denom"
               
                >{{numberFormat( b.amount )}}<span v-if="b.denom === 'tpp'"><v-icon small right>$vuetify.icons.custom</v-icon> </span> <span v-else>{{ b.denom }}</span> </span
              >
              on your balance.
            </span>
          </div> 
        </div>
       
      </div>
    </div>
   </div><div  v-if="!!privkey" >
        <v-btn block text
          small 
          
          @click="signOut"
        >
          Sign out
        </v-btn>
        
      </div>
  </div>
</template>

<style scoped>
.container {
  margin-bottom: 1.5rem;
}
.card {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 1rem;
 
  padding: 0.25rem 0.75rem;
  overflow-x: hidden;
}


.card__row {
  display: flex;
  align-items: center;


}

.card__desc {


  word-break: break-all;
}



.coin__amount {
  text-transform: uppercase;

  letter-spacing: 0.02em;
  font-weight: 600;
}
.coin__amount:after {
  content: ",";
  margin-right: 0.25em;
}
.coin__amount:last-child:after {
  content: "";
  margin-right: initial;
}

</style>

<script>

//import * as bip39 from "bip39";
import { DirectSecp256k1Wallet} from '@cosmjs/proto-signing/';
//import { makeCosmoshubPath } from '@cosmjs/launchpad'
import { fromHex } from "@cosmjs/encoding";
import { SigningStargateClient } from "@cosmjs/stargate";
import axios from "axios";
import { auth } from "./firebase/db.js";

export default {
 
 
  data() {
    return {
      password: "",
      error: false,

    
    };
  },
  computed: {
    account() {
      return this.$store.state.account;
    },
    address() {
      const client = this.$store.getters.account
      //console.log(client)
      return client && client.address
    
    },
   
    passwordClean() {
      return this.password.trim();
    },

    balances() {
      //console.log(this.$store.state.bankBalances)
			return this.$store.getters.bankBalances;
		},
    privkey() {
    return localStorage.getItem('privkey')
    },
  

  },
  methods: {
    
    trySignIn(){
console.log(this.password)
  console.log("try sign in")
      this.password = this.privkey
      this.signIn()
    },
    
    async signIn() {
      console.log("sign in")
      if ( !this.error) {
        const privkey = this.passwordClean;
       

      const wallet = await DirectSecp256k1Wallet.fromKey(
      fromHex(privkey),
       // makeCosmoshubPath(0),
        "trust"
      )
  

console.log(wallet)
    
     
      const { address } = wallet
      const API = process.env.VUE_APP_API
      const RPC = process.env.VUE_APP_RPC
        const url = `${API}/cosmos/auth/v1beta1/accounts/${address}`
      const acc = (await axios.get(url)).data
     // console.log(acc)
      const account = acc.account

     this.$store.commit('set', { key: 'wallet', value: wallet })
     this.$store.commit('set', { key: 'account', value: account })

      const client = await SigningStargateClient.connectWithSigner(RPC, wallet, {});
      this.$store.commit('set', { key: 'client', value: client })

      try {
        await this.$store.dispatch('bankBalancesGet')
      } catch {
        this.signOut()
        console.log('Error in getting a bank balance.')
      }
  
        this.initConfig();
         localStorage.setItem('privkey', privkey)
      }
    },
     async signOut() {
      if (this.address) {
          console.log("sign out")
				this.$store.dispatch('accountSignOut')
      };
      auth.signOut().then(() => {
         window.localStorage.removeItem('emailForSignIn');
          window.localStorage.removeItem('emailRef');
          window.localStorage.removeItem('privkey');
           //window.localStorage.removeItem('privkey');
    
  // Sign-out successful.
}).catch((error) => {
  // An error happened.
});
      
    },
    numberFormat(number) {
			return Intl.NumberFormat().format(number)
		},
  //set the app according to the logged in user
    async initConfig() {
   const type = { type: "estimators" };
      this.$store.dispatch("entityFetch",type);
       this.$store.dispatch("setEstimatorItemList", this.address);
       this.$store.dispatch("setToEstimateList", this.address);
     this.$store.dispatch("setTransactions", this.address)
       this.$store.dispatch("setSellerActionList", this.address);
        //this.$store.dispatch("setSortedTagList");
         this.$store.dispatch("setSellerItemList", this.address);
          this.$store.dispatch("setBuyerItemList", this.address);

       this.$store.dispatch("setInterestedItemList", this.address);

    this.$store.dispatch("setCodeHash");
    this.$store.dispatch("setMasterKey");

      this.$emit('signedIn');
      
      


    }
  },
};
</script>